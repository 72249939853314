
          @import "@/css/_mixins.scss";
          @import "@/css/_vars.scss";
        





































.page {
  list-style-type: none;
  margin: 0;
  padding: 0;

  .featured-image {
    max-width: 15em;
    margin: 0.5em auto;
  }
}
